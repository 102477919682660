<template>
  <div class="bkColor">
    <van-search
      v-model="value"
      input-align="center"
      placeholder="请输入搜索关键词"
      shape="round"
      show-action
      @search="onSearch"
      @cancel="onCancel"
      style="margin-bottom: 2vh"
    />
    <div class="search">
      <div>审核状态：</div>
      <div
        style="
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
      >
        <van-dropdown-menu style="margin-right: 20px">
          <van-dropdown-item
            v-model="queryFrom.state"
            :options="stateOption"
            @change="changePjState"
          />
        </van-dropdown-menu>
        <div class="searchTitle">支付状态:</div>
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="queryFrom.isSettle"
            :options="isSettleOption"
            @change="changeIsSettle"
          />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          finished-text="没有更多了"
          @load="getPaymentList"
        >
          <div
            class="listCell"
            v-for="item in list"
            :key="item.id"
            @mouseup="up($event)"
            @mousedown="down($event)"
            @click="goDetails(item.id)"
          >
            <van-swipe-cell
              :disabled="
                !powerList.includes('2') ||
                item.state !== 1 ||
                item.isSettle !== 0
              "
            >
              <div class="cellName">
                {{ item.userName + "的" + item.paymentName }}付款
                <div class="status">
                  <span
                    :class="{
                      blue: item.state == 0,
                      green: item.state == 1,
                      red: item.state == 2,
                    }"
                  >
                    {{ stateText(item.state) }}</span
                  ><br />
                  <span
                    :class="{
                      blue: item.isSettle == 0,
                      green: item.isSettle == 1,
                      red: item.isSettle == 2,
                    }"
                    >{{ settlement(item.isSettle) }}</span
                  >
                </div>
              </div>
              <div class="cellText">流水号：{{ item.id }}</div>
              <div class="cellText">
                付款说明：
                <div
                  style="width: 40%; display: inline-block; vertical-align: top"
                >
                  {{ item.description }}
                </div>
              </div>
              <div class="cellText">付款金额：{{ item.price }}元</div>
              <div class="cellText">付款日期：{{ item.paymentTime }}</div>
              <template #right>
                <van-button
                  color="#CCCCCC"
                  native-type="button"
                  @click="finalConfirmation(item.id, 2)"
                  >财务拒绝</van-button
                >
                <van-button
                  type="info"
                  native-type="button"
                  @click="finalConfirmation(item.id, 1)"
                  >财务确认</van-button
                >
              </template>
            </van-swipe-cell>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="addBtn">
      <img src="../../assets/icon/xz_icon.png" alt="" @click="add" />
    </div>
  </div>
</template>
  
<script>
import Vue from "vue";
import {
  Search,
  Toast,
  List,
  PullRefresh,
  Cell,
  SwipeCell,
  Button,
  Dialog,
} from "vant";

import { projectStatusText, settlementText } from "../../units/units";
import { getToken } from "../../units/auth";
import { paymentList, paymentUpdateState } from "../../api/payment";

Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(SwipeCell);
Vue.use(Button);
Vue.use(Dialog);
export default {
  name: "noPjPayment",
  props: {
    isRefresh: {
      typeof: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "项目付款列表",
      stateOption: [
        { text: "全部", value: null },
        { text: "审核中", value: 0 },
        { text: "审核通过", value: 1 },
        { text: "审核不通过", value: 2 },
      ],
      isSettleOption: [
        { text: "全部", value: null },
        { text: "未支付", value: 0 },
        { text: "已支付", value: 1 },
        { text: "拒绝支付", value: 2 },
      ],
      value: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      pageX: 0,
      pageY: 0,
      isDrag: false,
      queryFrom: {
        state: null,
        vague: "",
        pageSize: 10,
        pageNum: 1,
        additional: 1, //非项目类型判断
        isSettle: null,
      },
      powerList: [], //value 1总经理 2财务 3行政
      userId: "",
    };
  },
  activated() {
    if (this.isRefresh) {
      this.onRefresh();
    }
  },
  mounted() {
    this.getUserId();
    this.getPowerList();
  },
  methods: {
    //获取权限渲染页面
    getPowerList() {
      this.powerList = JSON.parse(getToken("powerList") || "[]");
    },
    getUserId() {
      this.userId = getToken("userId");
    },
    getPaymentList() {
      this.loading = true;
      paymentList(this.queryFrom).then((res) => {
        this.list =
          this.queryFrom.pageNum == 1
            ? res.data.rows
            : this.list.concat(res.data.rows);
        this.refreshing = false;
        this.loading = false;
        this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
        if (
          res.data.total <=
          (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize
        ) {
          this.finished = true;
        }
      });
    },
    //财务是否结算
    settlement(f) {
      return settlementText(f);
    },
    stateText(t) {
      return projectStatusText(t);
    },
    changePjState(val) {
      this.queryFrom.state = val;
      this.onRefresh();
    },
    changeIsSettle(val) {
      this.queryFrom.isSettleOption = val;
      this.onRefresh();
    },
    onSearch(val) {
      this.queryFrom.vague = val;
      this.onRefresh();
      // this.$router.push({ path: '/preview', query: { url: val } })
    },
    //搜索框取消
    onCancel() {
      this.queryFrom.vague = "";
      this.queryFrom.projectState = null;
      this.onRefresh();
    },
    //下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.queryFrom.pageNum = 1;
      this.list = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.getPaymentList();
    },
    add() {
      this.$router.push({
        path: "/newPjPayment",
        query: { additional: 1, route: "/nonProject" },
      });
    },
    goDetails(id) {
      if (this.isDrag) {
        return;
      }
      this.$router.push({
        path: "/paymentDetails",
        query: { id: id, route: "/nonProject" },
      });
    },
    up(event) {
      if (this.pageX !== event.pageX) {
        this.isDrag = true;
      } else {
        this.isDrag = false;
      }
    },
    down(event) {
      this.pageX = event.pageX;
      this.pageY = event.pageY;
    },
    finalConfirmation(id, val) {
      Dialog.confirm({
        message: `是否${val == 1 ? "确认" : "拒绝"}该报销`,
        confirmButtonColor: "#0092fe",
      }).then(() => {
        paymentUpdateState(id, val).then((res) => {
          if (res.data.code == 200) {
            Toast.success("完成操作");
            this.onRefresh();
          }
        });
      });
    },
  },
};
</script>
  
<style lang="less" scoped>
.blue {
  color: #1989fa;
}

.red {
  color: #e86f61;
}

.green {
  color: #00ab45;
}

.bkColor {
  width: 100vw;
  // height: 100vh;
  background: #f6f6f6;
  position: relative;
}

.search {
  width: 100vw;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  background: #fff;
  font-size: 15px;
  line-height: 8vh;
}

.searchTitle {
  margin-left: 6vw;
}

.list {
  margin-top: 2vh;
}

.listCell {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 5vw 1vh;
  background: #fff;
  text-align: left;
}

.cellName {
  width: 90%;
  font-size: 1rem;
  font-weight: 600;
  border-top: 2px solid #f6f6f6;
  padding-top: 1vh;
  padding-bottom: 1vh;

  .status {
    float: right;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: right;
  }
}

/deep/ .van-list :first-of-type.listCell .cellName {
  border-top: unset;
}

.cellText {
  width: 70%;
  color: #797979;
  margin-bottom: 0.5vh;
  font-size: 0.7rem;
  word-break: break-all;
  line-height: 1;
}

.addBtn {
  width: 70px;
  height: 70px;
  position: fixed;
  top: 77%;
  left: 77%;

  img {
    width: 70px;
    height: 70px;
  }
}

/deep/ .van-button {
  height: 100%;
}
</style>