<template>
    <div class="bkColor">
        <headTitle :title="title"></headTitle>
        <van-tabs v-model="active" @change="onChange">
            <van-tab title="付款" name='0'>
                <noPjPayment v-if="active == 0" :isRefresh="isRefresh"></noPjPayment>
            </van-tab>
            <van-tab title="报销" name='1'>
                <noPjReimbursement v-if="active == 1" :isRefresh="isRefresh"></noPjReimbursement>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import headTitle from '../components/headTitle.vue';
import Vue from 'vue';
import { Tab, Tabs } from 'vant';

Vue.use(Tab);
Vue.use(Tabs);

import noPjPayment from '../components/noProject/noPjPayment.vue'
import noPjReimbursement from '../components/noProject/noPjReimbursement.vue'
export default {
    name: 'nonProject',
    components: {
        headTitle,
        noPjPayment,
        noPjReimbursement
    },
    data() {
        return {
            title: '非项目付款',
            active: 0,
            isRefresh: false
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.path == '/newPjPayment' || from.path == '/newPjReimbursement') {
            next(vm => {
                vm.isRefresh = true
            })
        }
        next()
    },
    methods: {
        onChange(name, title) {
            this.title = '非项目' + title
            this.isRefresh = false
        }
    }

}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #F6F6F6;
    position: relative;
}
</style>